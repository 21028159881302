<template>
  <SlideUpDown class="mb-3" @click.native="handleOpen" :opened="opened">
    <template #header>
      <span class="curator-name">
        {{ curator.name || curator.user.email }}
      </span>
    </template>
    <template>
      <form class="form mb-4">
        <CInput
          v-model.trim="model.name"
          :label="$t('lk.training.curatorModal.name')"
          :placeholder="$t('lk.training.curatorModal.namePlaceholder')"
          :disabled="isFormLoading"
          type="text"
        />
        <CInput
          v-model.trim="model.tg_nick"
          :label="$t('lk.training.curatorModal.telegram')"
          :placeholder="$t('lk.training.curatorModal.telegramPlaceholder')"
          :disabled="isFormLoading"
          prepend="@"
          type="text"
        />
        <CButton
          color="primary"
          @click.prevent="submitForm"
          :disabled="isFormLoading"
        >
          {{ $t('general.save') }}
        </CButton>
        <CButton
          color="success"
          :disabled="isFormLoading"
          :to="{name: 'edit-curator-author', params: {curator_id: curator.id, back: $route}}"
        >
          <CIcon name="cil-pencil" />
        </CButton>
      </form>
      <div class="table-wrapper" v-if="curator.event_users.length">
        <table class="table mb-0">
          <thead>
            <tr>

              <th v-for="(field,idx) in fields" :key="'CuratorCard-' + idx" class="align-middle">
                <span class="sortable" @click="sortStudent(field.key)">
                  {{ field.label }}
                  <CIcon
                    name="arrowFilled"
                    width="0.5rem"
                    class="ml-2"
                    v-if="sortBy == field.key"
                    :class="{ reversed: !isAlphabetical }"
                  />
                </span>
              </th>
              <th v-for="(lesson, i) in lessonsWithHomeTask" :key="lesson.id" class="align-middle">
                <span v-tooltip="{ content: lesson.name }">
                  {{
                    $t(
                      isMobile || isTablet ? 'lk.curator.homeWorkAbbr' : 'lk.curator.homeWorkShort'
                    )
                  }}
                  {{ i + 1 }}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="eventUser in eventUsers" :key="eventUser.id">
              <td class="align-middle">
                {{ eventUser.user.name }}
              </td>
              <td class="align-middle">
                {{ getTariff(eventUser.tariff) }}
              </td>
              <td class="align-middle">
                {{eventUser.education_flows?.[0]?.flow?.name}}
              </td>
              <td class="align-middle">
                <div class="hasMentorshipIcon">
                  <CIcon
                    v-if="eventUser.has_mentor"
                    name="check"
                  />
                </div>
              </td>
              <td class="align-middle">
                <a target="_blank" :href="`https://t.me/${eventUser.user.tg_nick}`">{{ eventUser.user.tg_nick }}</a>
              </td>
              <td class="align-middle">
                {{toLocaleDate(eventUser.created_at)}}
              </td>
              <td v-for="lesson in lessonsWithHomeTask" :key="lesson.id" class="align-middle">
                <CuratorCheckbox
                  v-if="isLoaded"
                  :approval="taskApproval(eventUser, lesson)"
                  @change="handleCheckboxClick(eventUser, lesson)"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <CElementCover v-if="isLoading" class="cover" />
      </div>
      <p v-else>{{ $t(`lk.curator.noStudents`) }}</p>
    </template>
  </SlideUpDown>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import SlideUpDown from '@/components/common/SlideUpDown'
import CuratorCheckbox from './CuratorCheckbox'
import Lesson from '@/assets/js/Lesson.class';

export default {
  name: 'CuratorCard',
  components: {
    SlideUpDown,
    CuratorCheckbox,
  },

  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    eventId: {
      type: Number,
      required: true,
    },
    curator: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      isFormLoading: false,
      isLoaded: false,
      isAlphabetical: true,
      sortBy: 'name',
      lessons: [],
      approvals: [],
      model: null,
      fields: [
        {
          key: 'name',
          label: this.$t('lk.curator.student')
        },
        {
          key: 'tariff',
          label: this.$t('lk.curator.tariff')
        },
        {
          key: 'flow',
          label: this.$t('lk.training.studentTable.flow'),
        },
        {
          key: 'mentorship',
          label: 'Mentorship',
        },
        {
          key: 'telegram.chat.username',
          label: this.$t('lk.curator.tg')
        },
        {
          key: 'created_at',
          label: this.$t('lk.curator.buyDate')
        },
      ]
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'mediaQuery/isMobile',
      isTablet: 'mediaQuery/isTablet',
    }),

    eventUsers() {
      let sortedData = []
      if (this.sortBy == 'created_at') {
        sortedData = [...this.curator.event_users].sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at)
        })
      } else {
        sortedData = [...this.curator.event_users].sort((a, b) => {
          return this.lookup(a.user, this.sortBy).toLowerCase().localeCompare(this.lookup(b.user, this.sortBy).toLowerCase())
        })
      }
      return this.isAlphabetical ? sortedData : sortedData.reverse()
    },
    lessonsWithHomeTask() {
      return this.lessons.filter(lesson => lesson.has_homework && lesson.published)
    },
  },

  beforeMount() {
    this.model = { ...this.curator }
    if (this.opened && this.curator?.event_users?.length) {
      this.getLessons()
    }
  },

  methods: {
    sortStudent(key) {

      if(key == this.sortBy) {
        this.isAlphabetical = !this.isAlphabetical
      } else {
        this.isAlphabetical = true
        this.sortBy = key
      }
    },

    toLocaleDate(inputDate) {
      let date = new Date(inputDate)
      return date.toLocaleString('en-GB');
    },

    lookup(obj, key) {
      let looking = key.split('.').reduce((o, k) => o && o[k], obj)
      return looking ? looking : '';
    },

    getTariff(item) {
      if(item?.id === 'standard_edu'){
        return 'Standard'
      }
      if(item?.id === 'web3_edu'){
        return 'WEB3'
      }
      if(item?.id === 'crypto_basics_edu'){
        return 'Crypto Basics'
      }
      return 'All in'
    },

    handleOpen() {
      if (!this.isLoaded) {
        this.getLessons()
      }
    },

    async getLessons() {
      this.isLoading = true

      await axios
        .get(`/api/v2/events/${this.eventId}/lessons`)
        .then(({ data }) => {
          this.lessons = data.data.map(item => new Lesson(item))
          this.isLoaded = true
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    async handleCheckboxClick(user, lesson) {
      this.isLoading = true

      const approvalIndex = lesson.approvals.findIndex((item) => item.event_user_id === user.id)
      if (approvalIndex !== -1) {
        await axios
          .delete(`/api/v1/approvals/${lesson.approvals[approvalIndex].id}`)
          .then(() => {
            this.$toastr.success(this.$t('lk.curator.complete'))
            lesson.approvals.splice(approvalIndex, 1)
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        await axios
          .post(`/api/v1/approvals`, {
            event_id: lesson.id,
            event_user_id: user.id,
          })
          .then(({ data }) => {
            this.$toastr.success(this.$t('lk.curator.complete'))
            lesson.approvals.push(data.data)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },

    getEventUserIds() {
      const ids = []
      this.curator.event_users.forEach((item) => ids.push(item.id))
      return ids
    },

    taskApproval(user, lesson) {
      return lesson.approvals.find((item) => item.event_user_id === user.id)
    },

    submitForm() {
      if (!this.isFormLoading && this.model) {
        this.isFormLoading = true
        axios({
          method: 'put',
          url: `/api/v1/curators/${this.curator.id}`,
          data: this.model,
        })
          .then(({ data }) => {
            this.$toastr.success(this.$t('lk.training.curatorModal.completeUpdate'))
            this.$emit('update', data.data)
          })
          .finally(() => {
            this.isFormLoading = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.hasMentorshipIcon{
  display: flex;
  justify-content: center;
}
.curator-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  @include media-breakpoint-up(md) {
    font-size: 21px;
  }
}
.table {
  position: relative;

  thead tr th {
    white-space: nowrap;
    border-top: none;
    background: var(--second-card-bg);
    position: sticky;
    top: -1px;
    z-index: 1;
  }
}
.table-wrapper {
  position: relative;
  overflow: overlay;
  max-height: 350px;
  margin-bottom: 16px;
}
.sortable {
  cursor: pointer;
}
.reversed {
  transform: rotate(180deg);
}
.cover {
  z-index: 1;
}

.form {
  @include media-breakpoint-up(lg) {
    display: flex;
    gap: 20px;
  }
  .form-group {
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 0;
      ::v-deep label {
        margin-bottom: 0;
      }
    }
  }
}
</style>
