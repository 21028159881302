var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slideUpDown", class: { _opened: _vm.isOpened } },
    [
      _c(
        "div",
        {
          staticClass: "d-flex w-100 slideUpDown__header",
          on: {
            click: function ($event) {
              _vm.isOpened = !_vm.isOpened
            },
          },
        },
        [
          _vm._t("header"),
          _c(
            "span",
            {
              staticClass: "slideUpDown__btn",
              class: { _rotate: _vm.isOpened },
            },
            [
              _c("CIcon", {
                staticClass: "accordion-icon",
                attrs: { name: "arrow-trends", width: "1rem" },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "transition",
        {
          attrs: { name: "acc" },
          on: {
            "before-enter": function ($event) {
              $event.style.height = "0px"
            },
            enter: function ($event) {
              $event.style.height = "fit-content"
            },
            "before-leave": function ($event) {
              $event.style.height = "fit-content"
            },
            leave: function ($event) {
              $event.style.height = "0px"
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isOpened,
                  expression: "isOpened",
                },
              ],
              staticClass: "slideUpDown__content",
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }