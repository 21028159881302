var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "SlideUpDown",
    {
      staticClass: "mb-3",
      attrs: { opened: _vm.opened },
      nativeOn: {
        click: function ($event) {
          return _vm.handleOpen.apply(null, arguments)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("span", { staticClass: "curator-name" }, [
                _vm._v(
                  " " + _vm._s(_vm.curator.name || _vm.curator.user.email) + " "
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      [
        _c(
          "form",
          { staticClass: "form mb-4" },
          [
            _c("CInput", {
              attrs: {
                label: _vm.$t("lk.training.curatorModal.name"),
                placeholder: _vm.$t("lk.training.curatorModal.namePlaceholder"),
                disabled: _vm.isFormLoading,
                type: "text",
              },
              model: {
                value: _vm.model.name,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.model,
                    "name",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "model.name",
              },
            }),
            _c("CInput", {
              attrs: {
                label: _vm.$t("lk.training.curatorModal.telegram"),
                placeholder: _vm.$t(
                  "lk.training.curatorModal.telegramPlaceholder"
                ),
                disabled: _vm.isFormLoading,
                prepend: "@",
                type: "text",
              },
              model: {
                value: _vm.model.tg_nick,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.model,
                    "tg_nick",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "model.tg_nick",
              },
            }),
            _c(
              "CButton",
              {
                attrs: { color: "primary", disabled: _vm.isFormLoading },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.submitForm.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("general.save")) + " ")]
            ),
            _c(
              "CButton",
              {
                attrs: {
                  color: "success",
                  disabled: _vm.isFormLoading,
                  to: {
                    name: "edit-curator-author",
                    params: { curator_id: _vm.curator.id, back: _vm.$route },
                  },
                },
              },
              [_c("CIcon", { attrs: { name: "cil-pencil" } })],
              1
            ),
          ],
          1
        ),
        _vm.curator.event_users.length
          ? _c(
              "div",
              { staticClass: "table-wrapper" },
              [
                _c("table", { staticClass: "table mb-0" }, [
                  _c("thead", [
                    _c(
                      "tr",
                      [
                        _vm._l(_vm.fields, function (field, idx) {
                          return _c(
                            "th",
                            {
                              key: "CuratorCard-" + idx,
                              staticClass: "align-middle",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "sortable",
                                  on: {
                                    click: function ($event) {
                                      return _vm.sortStudent(field.key)
                                    },
                                  },
                                },
                                [
                                  _vm._v(" " + _vm._s(field.label) + " "),
                                  _vm.sortBy == field.key
                                    ? _c("CIcon", {
                                        staticClass: "ml-2",
                                        class: {
                                          reversed: !_vm.isAlphabetical,
                                        },
                                        attrs: {
                                          name: "arrowFilled",
                                          width: "0.5rem",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        _vm._l(_vm.lessonsWithHomeTask, function (lesson, i) {
                          return _c(
                            "th",
                            { key: lesson.id, staticClass: "align-middle" },
                            [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: { content: lesson.name },
                                      expression: "{ content: lesson.name }",
                                    },
                                  ],
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          _vm.isMobile || _vm.isTablet
                                            ? "lk.curator.homeWorkAbbr"
                                            : "lk.curator.homeWorkShort"
                                        )
                                      ) +
                                      " " +
                                      _vm._s(i + 1) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.eventUsers, function (eventUser) {
                      return _c(
                        "tr",
                        { key: eventUser.id },
                        [
                          _c("td", { staticClass: "align-middle" }, [
                            _vm._v(" " + _vm._s(eventUser.user.name) + " "),
                          ]),
                          _c("td", { staticClass: "align-middle" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.getTariff(eventUser.tariff)) +
                                " "
                            ),
                          ]),
                          _c("td", { staticClass: "align-middle" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  eventUser.education_flows?.[0]?.flow?.name
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", { staticClass: "align-middle" }, [
                            _c(
                              "div",
                              { staticClass: "hasMentorshipIcon" },
                              [
                                eventUser.has_mentor
                                  ? _c("CIcon", { attrs: { name: "check" } })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _c("td", { staticClass: "align-middle" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  target: "_blank",
                                  href: `https://t.me/${eventUser.user.tg_nick}`,
                                },
                              },
                              [_vm._v(_vm._s(eventUser.user.tg_nick))]
                            ),
                          ]),
                          _c("td", { staticClass: "align-middle" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.toLocaleDate(eventUser.created_at)) +
                                " "
                            ),
                          ]),
                          _vm._l(_vm.lessonsWithHomeTask, function (lesson) {
                            return _c(
                              "td",
                              { key: lesson.id, staticClass: "align-middle" },
                              [
                                _vm.isLoaded
                                  ? _c("CuratorCheckbox", {
                                      attrs: {
                                        approval: _vm.taskApproval(
                                          eventUser,
                                          lesson
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleCheckboxClick(
                                            eventUser,
                                            lesson
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ]),
                _vm.isLoading
                  ? _c("CElementCover", { staticClass: "cover" })
                  : _vm._e(),
              ],
              1
            )
          : _c("p", [_vm._v(_vm._s(_vm.$t(`lk.curator.noStudents`)))]),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }