<template>
  <div>
    <div class="pageCurators">
      <h1 class="mb-4">
        {{ $t('lk.training.studentTable.curator') }}
      </h1>
      <div>
        <template v-if="event_id && curators.length">
          <CuratorCard
            v-for="(curator, index) in curators"
            :key="curator.id"
            :curator="curator"
            :event-id="event_id"
            :opened="index === 0"
            @update="handleUpdate"
          />
        </template>
        <p v-else-if="!isLoading">{{ $t(`general.noData`) }}</p>
        <CElementCover v-if="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import SlideUpDown from '@/components/common/SlideUpDown'
import CuratorCard from '@/components/curator/CuratorCard'

export default {
  name: 'PageCurator',
  components: {
    SlideUpDown,
    CuratorCard,
  },
  props: {
    eventId: {
      type: Number|null,
      default: null,
    },
    slug: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      curators: [],
      event_id: this.eventId
    }
  },

  mounted() {
    this.getCurators()
  },

  methods: {
    async getCurators() {
      this.isLoading = true

      if(!this.event_id){
        await axios
          .get('/api/v2/courses/' + this.slug)
          .then(({ data }) => {
            this.event_id = Number(data.data.id)
            this.$emit('update:eventId', this.event_id)
          })
      }

      await axios
        .get('/api/v1/curators', {
          params: {
            event_id: this.event_id,
          },
        })
        .then(({ data }) => {
          this.curators = [...data.data]
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    handleUpdate(curator) {
      const index = this.curators.findIndex(el => el.id === curator.id)
      this.curators.splice(index, 1, curator)
    }
  },
}
</script>
