import { get, replace, split } from 'lodash'
import moment from "moment";
import store from "@/store";

export default class Lesson {
  constructor(init) {
    this.id = get(init, 'id', 0)
    this.name = get(init, 'name', '')
    this.theme = get(init, 'theme', '')
    this.description = get(init, 'description', '')
    this.has_homework = get(init, 'has_homework', Boolean(this.description))
    this.live = get(init, 'live', false)
    this.streamUrl = get(init, 'stream_url', '')
    this.startAt = get(init, 'start_at', false)
    this.finishAt = get(init, 'finish_at', false)
    this.publishedAt = get(init, 'published_at', false)
    this.order = get(init, 'order', 0)
    this.newOrder = get(init, 'newOrder', 0)
    this.shortDescription = get(init, 'short_description', '')
    this.videos = get(init, 'videos', [])
    this.primaryVideoId = get(init,'primaryVideoId', null)
    this.title_page = get(init, 'title_page', '')
    this.chat = get (init, 'chat', '')
    this.approvals = get(init, 'approvals', [])
    this.published = get(init, 'published', true)
    this.chatFileName = get(init, 'chat_file_name', '')
    this.flows = get(init, 'educationFlows', [])
    this.accessFlows = get(init, 'accessFlows', []).map(item => {
      return this.flows.find(el => el.id === item) || {
        id: item,
        name: item
      }
    })
    this.accessType = get(init, 'accessType', 'all')
    this.accessInitType = this.accessFlows.length === 0 ? this.accessType : 'all';
  }

  get startAtFormatted() {
    if (this.startAt) {
      const momentDate = moment(this.startAt)
      if (momentDate.isValid()) {
        return momentDate.format('YYYY-MM-DDTHH:mm')
      } else {
        return false
      }
    } else {
      return false
    }
  }

  set startAtFormatted(value) {
    if (value === '') {
      this.startAt = false
    }
    const momentDate = moment(value)
    if (momentDate.isValid()) {
      this.startAt = momentDate.format('YYYY-MM-DDTHH:mm')
    }
  }

  get finishAtFormatted() {
    if (this.finishAt) {
      const momentDate = moment(this.finishAt)
      if (momentDate.isValid()) {
        return momentDate.format('YYYY-MM-DDTHH:mm')
      } else {
        return false
      }
    } else {
      return false
    }
  }

  set finishAtFormatted(value) {
    const momentDate = moment(value)
    if (momentDate.isValid()) {
      this.finishAt = momentDate.format('YYYY-MM-DDTHH:mm')
    }
  }

  get videoEmbedVimeoCode() {
    if (this.streamUrl) {
      const splitted = this.streamUrl.split('/')
      const code = splitted.find(el => parseInt(el) > 0)
      return `https://vimeo.com/event/${code}/embed`
    } else {
      return false
    }
  }

  get chatEmbedVimeoCode() {
    if (this.streamUrl) {
      const splitted = this.streamUrl.split('/')
      const code = splitted.find(el => parseInt(el) > 0)
      return `https://vimeo.com/event/${code}/chat`
    } else {
      return false
    }
  }

  get videoDescFormatterd() {
    let mapVideo = this.videos.map(el => {
      if(el) {
        return el;
      }
    })
    return mapVideo.filter(el => typeof el == 'object')
  }

  get shortDescriptionForamtted() {
    return this.shortDescription
  }

  get descriptionFormatted() {
    return this.description
  }

  get approval() {
    return this.approvals.find(item => item.user_id === store.state.user.userData.id)
  }

  get formData() {
    const fd = new FormData()
    if (this.name) {
      fd.append('name', this.name)
    }
    if (this.order) {
      fd.append('order', this.order)
    }
    fd.append('has_homework', this.has_homework ? '1' : '0')
    fd.append('description', this.description)
    fd.append('short_description', this.shortDescription)
    if (this.theme) {
      fd.append('theme', this.theme)
    }
    fd.append('live', this.live ? '1' : '0')
    if (this.streamUrl) {
      fd.append('stream_url', this.streamUrl)
    }
    if (this.startAtFormatted) {
      fd.append('start_at', this.startAt)
    } else {
      fd.append('start_at', '')
    }
    if (this.finishAtFormatted) {
      fd.append('finish_at', this.finishAt)
    }
    if (this.publishedAt === false) {
      fd.append('published_at', '')
    } else if (this.publishedAt !== true) {
      fd.append('published_at', this.publishedAt)
    }
    fd.append('primaryVideoId', this.primaryVideoId)
    fd.append('videos', JSON.stringify(this.videos))
    if (this.title_page) {
      fd.append('title_page', this.title_page)
    }
    if (this.chat && this.chat instanceof File) {
      fd.append('chat', this.chat)
    }

    fd.append('access', this.accessFlows.length ? 'flows' : 'all');
    fd.append('accessFlows', this.accessFlows.map(item => item.id).join(','))
    return fd
  }
}
