var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    [
      _c("input", {
        attrs: { type: "checkbox" },
        domProps: { checked: _vm.approval },
        on: {
          change: function ($event) {
            return _vm.$emit("change")
          },
        },
      }),
      _vm.approval
        ? _c("CIcon", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: {
                  content: _vm.tooltipContent(),
                  html: true,
                },
                expression:
                  "{\n      content: tooltipContent(),\n      html: true,\n    }",
              },
            ],
            attrs: { width: "1rem", name: "fileOk" },
          })
        : _c("CIcon", { attrs: { width: "1rem", name: "fileError" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }