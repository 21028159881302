<template>
  <div class="slideUpDown" :class="{ _opened: isOpened }">
    <div @click="isOpened = !isOpened" class="d-flex w-100 slideUpDown__header">
      <slot name="header"></slot>
      <span class="slideUpDown__btn" :class="{ _rotate: isOpened }">
        <CIcon name="arrow-trends" width="1rem" class="accordion-icon" />
      </span>
    </div>
    <transition
      name="acc"
      @before-enter="$event.style.height = '0px'"
      @enter="$event.style.height = 'fit-content'"
      @before-leave="$event.style.height = 'fit-content'"
      @leave="$event.style.height = '0px'"
    >
      <div v-show="isOpened" class="slideUpDown__content">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'SlideUpDown',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpened: false,
    }
  },
  mounted() {
    this.isOpened = this.opened
  },
}
</script>

<style lang="scss" scoped>
.slideUpDown {
  background: var(--second-card-bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;

  &._opened {
    background: var(--tab-active-bg);
  }

  &__header {
    cursor: pointer;
    padding: 16px 24px;
  }

  &__content {
    width: 100%;
    transition: 0.6s;
    overflow: hidden;
    padding: 12px 24px 0;
  }

  &__btn {
    cursor: pointer;
    transform: rotate(90deg);
    margin-left: auto;
    transition: 0.5s;
    height: 20px;
    &._rotate {
      transform: rotate(-90deg);
    }
    .accordion-icon {
      transform: rotate(90deg);
    }
  }
}

.acc-enter-active,
.acc-leave-active {
  margin-top: 12px;

  @include media-breakpoint-up(md) {
  }
}

.acc-enter,
.acc-leave-to {
  margin-top: 0;
}
</style>
