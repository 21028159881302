<template>
  <label>
    <input type="checkbox" :checked="approval" @change="$emit('change')" />
    <CIcon
      v-if="approval"
      v-tooltip="{
        content: tooltipContent(),
        html: true,
      }"
      width="1rem"
      name="fileOk"
    />
    <CIcon v-else width="1rem" name="fileError" />
  </label>
</template>

<script>
import moment from 'moment'

export default {
  name: 'CuratorCheckbox',

  props: {
    approval: {
      type: Object,
    },
  },

  methods: {
    tooltipContent() {
      return `<div class='d-flex flex-column align-items-start'><span>${this.$t(
        'lk.curator.hwTooltip'
      )}</span> <span>${moment(this.approval?.created_at).format('DD.MM.YYYY')}</span></div>`
    },
  },
}
</script>>

<style lang="scss" scoped>
label {
  cursor: pointer;
  position: relative;
  margin-bottom: 0;
  input {
    position: absolute;
    width: 0;
    height: 0;
    visibility: hidden;
  }
  svg {
    outline: none;
  }
}
</style>
