var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "pageCurators" }, [
      _c("h1", { staticClass: "mb-4" }, [
        _vm._v(" " + _vm._s(_vm.$t("lk.training.studentTable.curator")) + " "),
      ]),
      _c(
        "div",
        [
          _vm.event_id && _vm.curators.length
            ? _vm._l(_vm.curators, function (curator, index) {
                return _c("CuratorCard", {
                  key: curator.id,
                  attrs: {
                    curator: curator,
                    "event-id": _vm.event_id,
                    opened: index === 0,
                  },
                  on: { update: _vm.handleUpdate },
                })
              })
            : !_vm.isLoading
            ? _c("p", [_vm._v(_vm._s(_vm.$t(`general.noData`)))])
            : _vm._e(),
          _vm.isLoading ? _c("CElementCover") : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }